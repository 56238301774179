import React, { useState } from 'react';
import DynamicQuestionnaire from './DynamicQuestionnaire';
import LoginForm from './components/LoginForm';
import './App.css';

function App() {
  const [showIntro, setShowIntro] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div className={`App ${isAuthenticated ? 'authenticated' : ''}`}>
      {!isAuthenticated ? (
        <LoginForm onAuthenticate={setIsAuthenticated} />
      ) : (
        <div className="app-content">
          {!showIntro && (
            <header className="App-header">
              <h1>PCare+</h1>
            </header>
          )}
          <DynamicQuestionnaire onIntroChange={setShowIntro} />
        </div>
      )}
    </div>
  );
}

export default App;