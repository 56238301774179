import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "./components/ui/card";
import { Input } from "./components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select";
import { Checkbox } from "./components/ui/checkbox";
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import placeholderImage from './assets/placeholder.png';
import logo from './assets/logo.png';
import cwcLogo from './assets/cwclogo.png';
import ctfLogo from './assets/ctflogo.png';
import cqmfLogo from './assets/cqmflogo.png';

// eslint-disable-next-line no-unused-vars
const ChildSVG = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="30" r="20" fill="#FFD700" /> {/* Head */}
    <circle cx="42" cy="25" r="3" fill="#000" /> {/* Left eye */}
    <circle cx="58" cy="25" r="3" fill="#000" /> {/* Right eye */}
    <path d="M40 35 Q50 45 60 35" stroke="#000" strokeWidth="2" fill="none" /> {/* Smile */}
    <rect x="35" y="50" width="30" height="30" fill="#FF6347" /> {/* Body */}
    <rect x="35" y="80" width="12" height="20" fill="#4682B4" /> {/* Left leg */}
    <rect x="53" y="80" width="12" height="20" fill="#4682B4" /> {/* Right leg */}
  </svg>
);

const AdultSVG = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="25" r="20" fill="#F4A460" /> {/* Head */}
    <circle cx="42" cy="20" r="3" fill="#000" /> {/* Left eye */}
    <circle cx="58" cy="20" r="3" fill="#000" /> {/* Right eye */}
    <path d="M40 30 Q50 40 60 30" stroke="#000" strokeWidth="2" fill="none" /> {/* Smile */}
    <rect x="35" y="45" width="30" height="40" fill="#4682B4" /> {/* Body */}
    <rect x="35" y="85" width="12" height="15" fill="#8B4513" /> {/* Left leg */}
    <rect x="53" y="85" width="12" height="15" fill="#8B4513" /> {/* Right leg */}
  </svg>
);

const SeniorSVG = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="25" r="20" fill="#FFE4B5" /> {/* Head */}
    <circle cx="42" cy="20" r="3" fill="#000" /> {/* Left eye */}
    <circle cx="58" cy="20" r="3" fill="#000" /> {/* Right eye */}
    <path d="M40 30 Q50 40 60 30" stroke="#000" strokeWidth="2" fill="none" /> {/* Smile */}
    <rect x="35" y="45" width="30" height="40" fill="#228B22" /> {/* Body */}
    <rect x="35" y="85" width="12" height="15" fill="#8B4513" /> {/* Left leg */}
    <rect x="53" y="85" width="12" height="15" fill="#8B4513" /> {/* Right leg */}
    <path d="M35 55 L20 75 L15 73" stroke="#8B4513" strokeWidth="4" fill="none" /> {/* Left arm */}
    <line x1="15" y1="73" x2="15" y2="95" stroke="#8B4513" strokeWidth="2" /> {/* Cane */}
  </svg>
);

const Watermark = ({ language }) => (
  <Text
    style={{
      position: 'absolute',
      opacity: 0.1,
      color: 'gray',
      fontSize: 60,
      transform: 'rotate(-45deg)',
      transformOrigin: 'center',
      left: 0,
      right: 0,
      top: '50%',
      textAlign: 'center',
      zIndex: -1,
    }}
    fixed
  >
    {language === 'fr' ? 'CONFIDENTIEL' : 'CONFIDENTIAL'}
  </Text>
);

const PDFDocument = ({ responses, findRiscScore, language }) => {
  const recommendations = getScreeningRecommendations(responses, findRiscScore, language);

  // Calculate BMI
  const heightInMeters = responses.ID30 / 100;
  const bmi = responses.ID31 / (heightInMeters * heightInMeters);

  // Classify BMI
  let bmiClassification;
  if (bmi < 18.5) bmiClassification = language === 'fr' ? "poids insuffisant" : "underweight";
  else if (bmi >= 18.5 && bmi < 25) bmiClassification = language === 'fr' ? "normal" : "normal";
  else if (bmi >= 25 && bmi < 30) bmiClassification = language === 'fr' ? "surpoids" : "overweight";
  else bmiClassification = language === 'fr' ? "obèse" : "obese";
  
  // Group conditions
  const groupedRecommendations = {
    [language === 'fr' ? "Dépistages du cancer" : "Cancer screening"]: [
      language === 'fr' ? "Cancer du sein" : "Breast cancer", 
      language === 'fr' ? "Cancer colorectal" : "Colorectal cancer", 
      language === 'fr' ? "Cancer du col de l'utérus" : "Cervical cancer", 
      language === 'fr' ? "Cancer du poumon" : "Lung cancer"
    ],
    [`${language === 'fr' ? "Maladies chroniques" : "Chronic diseases"}${responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') ? (language === 'fr' ? " et la santé de la femme" : " & women's health") : ""}`]: [
      "AAA", 
      language === 'fr' ? "Acide folique" : "Folic acid", 
      language === 'fr' ? "Hypertension" : "Hypertension", 
      language === 'fr' ? "Dyslipidémie" : "Dyslipidemia", 
      language === 'fr' ? "Risque de fracture de fragilité" : "Fragility fracture risk", 
      language === 'fr' ? "Diabète" : "Diabetes"
    ],
    [language === 'fr' ? "Maladies infectieuses et vaccins" : "Infectious diseases & immunizations"]: [
      language === 'fr' ? "ITSS" : "STIs", 
      language === 'fr' ? "Vaccin contre la grippe" : "Flu vaccine", 
      language === 'fr' ? "Vaccin contre le zona" : "Zoster vaccine", 
      language === 'fr' ? "Vaccin contre le pneumocoque" : "Pneumococcus vaccine", 
      language === 'fr' ? "Rappel contre le tétanos" : "Tetanus booster"
    ]
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Watermark language={language} />
        <View style={styles.header} fixed>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.headerText}>{language === 'fr' ? "Bilan de santé proactif : recommandations des cliniciens" : "Health Screening Recommendations"}</Text>
          <Text style={styles.headerDate}>{new Date().toLocaleDateString()}</Text>
        </View>
        
        <View style={styles.content}>
          <View style={styles.patientInfo}>
            <Image src={placeholderImage} style={styles.patientImage} />
            <View style={styles.patientDetails}>
            <Text style={styles.patientId}>ID: {responses.ID1}{(responses.ID2 === 'Male' || responses.ID2 === 'Homme') ? "M" : "F"}</Text>
            <Text style={styles.patientStats}>
                {language === 'fr' 
                  ? `Taille: ${responses.ID30} cm | Poids: ${responses.ID31} kg | IMC: ${bmi.toFixed(1)} (${bmiClassification})` 
                  : `Height: ${responses.ID30} cm | Weight: ${responses.ID31} kg | BMI: ${bmi.toFixed(1)} (${bmiClassification})`}
              </Text>
              <Text style={styles.patientStats}>
                {language === 'fr' 
                  ? `Antécédents médicaux : ${responses.ID5.length > 0 ? responses.ID5.join(", ") : "Aucun"}`
                  : `Past Medical History: ${responses.ID5.length > 0 ? responses.ID5.join(", ") : "None"}`
                }
              </Text>
              <Text style={styles.patientStats}>
                {language === 'fr' ? `Antécédents médicaux familiaux: ${[
                  ...responses.ID3,
                  (responses.ID4 === 'Oui (parent, frère ou sœur ou enfant)' || responses.ID4 === 'Oui (grand-parent, oncle/tante ou cousin germain)') ? 'Diabète' : null
                ].filter(Boolean).join(", ") || "Aucun"}` :
                `Family Medical History: ${[
                  ...responses.ID3,
                  (responses.ID4 === 'Yes (parent, sibling, or child)' || responses.ID4 === 'Yes (grandparent, uncle/aunt, or first cousin)') ? 'Diabetes' : null
                ].filter(Boolean).join(", ") || "None"}`}
              </Text>
              <Text style={styles.patientStats}>
                {language === 'fr' ? `Habitudes: ${getSmokingStatus(responses, language)} | ${getAlcoholStatus(responses, language)}` :
                `Habits: ${getSmokingStatus(responses, language)} | ${getAlcoholStatus(responses, language)}`}
              </Text>
            </View>
          </View>

          {Object.entries(groupedRecommendations).map(([group, conditions]) => (
            <View key={group} style={styles.recommendationsTable}>
              <View style={styles.groupTitleCell}>
                <Text style={styles.groupTitle}>{group}</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text style={[styles.tableHeaderCell, styles.conditionCell]}>{language === 'fr' ? "Condition" : "Condition"}</Text>
                <Text style={[styles.tableHeaderCell, styles.recommendationCell]}>{language === 'fr' ? "Recommendation" : "Recommendation"}</Text>
              </View>
              {conditions.map((condition, index) => {
                if (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male') && (condition === (language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer') || condition === (language === 'fr' ? 'Cancer du sein' : 'Breast cancer') || condition === (language === 'fr' ? 'Supplémentation en acide folique' : 'Folic acid supplementation'))) {
                  return null;
                }
                const recommendation = recommendations[condition] || (language === 'fr' ? "Aucune recommandation disponible" : "No recommendation available");
                const needsYellowBackground = 
                  (condition === (language === 'fr' ? 'Cancer du sein' : 'Breast cancer') && 
                  (responses.ID3.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') || 
                   responses.ID3.includes('mutation BRCA1/2') || 
                   responses.ID5.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') || 
                   responses.ID6 === (language === 'fr' ? 'Oui' : 'Yes'))) ||
                  (condition === (language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer') && 
                  (responses.ID5.includes(language === 'fr' ? "Cancer du col de l'utérus" : "Cervical cancer") || 
                   responses.ID13 === (language === 'fr' ? 'Oui' : 'Yes') || 
                   responses.ID14 === (language === 'fr' ? 'Oui' : 'Yes') ||
                   responses.ID11 === (language === 'fr' ? 'Oui' : 'Yes'))) ||
                  (condition === (language === 'fr' ? 'Risque de fracture de fragilité' : 'Fragility fracture risk') &&
                   responses.ID5.includes(language === 'fr' ? 'Fracture de fragilité (ostéoporose)' : 'Fragility fracture (osteoporosis)'));
                const needsGreenBackground = (condition === (language === 'fr' ? 'Hypertension' : 'Hypertension') && !responses.ID5.includes(language === 'fr' ? 'Hypertension' : 'Hypertension')) || 
                                             (condition === (language === 'fr' ? 'Vaccin contre la grippe' : 'Flu vaccine')) ||
                                             (condition === (language === 'fr' ? 'Cancer colorectal' : 'Colorectal cancer') && responses.ID9 === (language === 'fr' ? 'Non' : 'No')) ||
                                             (condition === (language === 'fr' ? 'Dyslipidémie' : 'Dyslipidemia') && 
                                              responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && 
                                              responses.ID1 >= 50 && 
                                              (responses.ID16 === (language === 'fr' ? 'Non' : 'No') || (responses.ID16 === (language === 'fr' ? 'Oui' : 'Yes') && responses.ID17 === (language === 'fr' ? 'Oui' : 'Yes')))) ||
                                             (condition === (language === 'fr' ? 'Vaccin contre le zona' : 'Zoster vaccine') && 
                                              responses.ID1 >= 50 && responses.ID1 < 75) ||
                                             (condition === (language === 'fr' ? 'Rappel contre le tétanos' : 'Tetanus booster') &&
                                              responses.ID1 >= 50 &&
                                              responses.ID24 === (language === 'fr' ? 'Non' : 'No'));
                return (
                  <View style={[styles.tableRow, index % 2 === 0 ? styles.evenRow : styles.oddRow]} key={condition}>
                    <Text style={[styles.tableCell, styles.conditionCell]}>{condition}</Text>
                    <Text style={[
                      styles.tableCell, 
                      styles.recommendationCell,
                      needsYellowBackground && styles.yellowRecommendationCell,
                      needsGreenBackground && styles.greenRecommendationCell
                    ]}>{recommendation}</Text>
                  </View>
                );
              })}
            </View>
          ))}
        </View>

        <View style={styles.footer} fixed>
          <View style={styles.footerLogos}>
            <Image src={cwcLogo} style={styles.footerLogo} />
            <Image src={ctfLogo} style={[styles.footerLogo, styles.centerLogo]} />
            <Image src={cqmfLogo} style={styles.footerLogo} />
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            language === 'fr' ? `Page ${pageNumber} sur ${totalPages}` : `Page ${pageNumber} of ${totalPages}`
          )} />
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: '#cccccc',
    paddingBottom: 10,
  },
  logo: {
    width: 100,
    height: 'auto',
  },
  headerText: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#333333',
  },
  headerDate: {
    fontSize: 10,
    color: '#666666',
  },
  content: {
    flex: 1,
  },
  patientInfo: {
    flexDirection: 'row',
    marginBottom: 10,
    height: 80, // Set a fixed height for the patient info section
  },
  patientImage: {
    width: 'auto',
    height: '100%', // Make the image height 100% of its container
    marginRight: 10,
    aspectRatio: 1, // Preserve aspect ratio
  },
  patientDetails: {
    flex: 1,
    justifyContent: 'space-between', // Distribute content evenly
  },
  patientId: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  patientStats: {
    fontSize: 10,
    marginBottom: 2,
  },
  patientBmi: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
    fontFamily: 'Helvetica-Bold',
  },
  sectionText: {
    fontSize: 10,
    lineHeight: 1.2,
  },
  recommendationsTable: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#000000',
  },
  tableHeader: {
    flexDirection: 'row',
    fontSize: 10,
    backgroundColor: 'rgba(220, 220, 220, 0.8)',
    borderBottomColor: '#000000',
    borderBottomWidth: 0,
  },
  tableHeaderCell: {
    padding: 4,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: 'rgba(238, 238, 238, 1)',
    borderBottomWidth: 0,
  },
  evenRow: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  oddRow: {
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  tableCell: {
    padding: 4,
    fontSize: 10,
    backgroundColor: 'transparent',
  },
  conditionCell: {
    width: '25%',
    borderRightWidth: 0,
    borderRightColor: '#000000',
  },
  recommendationCell: {
    width: '75%',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
  },
  footerLogos: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 35,
  },
  footerLogo: {
    width: 'auto',
    height: 25,
    marginHorizontal: 30,
  },
  centerLogo: {
    marginHorizontal: 20,
  },
  pageNumber: {
    fontSize: 10,
    textAlign: 'center',
    color: '#666666',
  },
  groupTitle: {
    fontSize: 10,
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
  },
  groupTitleCell: {
    backgroundColor: 'rgba(200, 200, 200, 0.7)',
    padding: 4,
    fontWeight: 'bold',
  },
  summarySection: {
    marginTop: 0,
    padding: 0,
  },
  summaryTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'left',
  },
  summarySubsection: {
    marginBottom: 10,
  },
  summarySubtitle: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  summaryItem: {
    fontSize: 10,
  },
  yellowRecommendationCell: {
    backgroundColor: 'rgba(255, 255, 224)',
  },
  greenRecommendationCell: {
    backgroundColor: 'rgba(144, 238, 144)',
  }
});

const getBreastCancerRecommendation = (responses, language) => {
  if (responses.ID2 === 'Homme') {
    return language === 'fr' ? "Aucun dépistage nécessaire" : "No screening needed";
  }
  
  if (responses.ID3.includes('Cancer du sein') || responses.ID3.includes('Breast cancer') ||
      responses.ID3.includes('mutation BRCA1/2') || responses.ID3.includes('BRCA1/2 mutation') ||
      responses.ID5.includes('Cancer du sein') || responses.ID5.includes('Breast cancer') ||
      responses.ID6 === 'Oui' || responses.ID6 === 'Yes') {
    let justification = language === 'fr' ? "Évaluation plus approfondie nécessaire en raison de " : "Further evaluation needed due to ";
    let reasons = [];

    if (responses.ID3.includes('Cancer du sein') || responses.ID3.includes('Breast cancer') ||
        responses.ID3.includes('mutation BRCA1/2') || responses.ID3.includes('BRCA1/2 mutation')) {
      reasons.push((language === 'fr' ? "l'antécédent familial de " : "family history of ") + 
        responses.ID3.filter(item => 
          ['Cancer du sein', 'Breast cancer', 'mutation BRCA1/2', 'BRCA1/2 mutation'].includes(item)
        ).map(item => item.charAt(0).toLowerCase() + item.slice(1)).join(", "));
    }

    if (responses.ID5.includes('Cancer du sein') || responses.ID5.includes('Breast cancer')) {
      reasons.push(language === 'fr' ? "antécédents personnels de cancer du sein" : "personal history of breast cancer");
    }

    if (responses.ID6 === 'Oui' || responses.ID6 === 'Yes') {
      reasons.push(language === 'fr' ? "antécédents de radiothérapie à la poitrine" : "previous radiation therapy to the chest");
    }

    justification += reasons.join(language === 'fr' ? " ET " : " AND ");
    return justification;
  }
  
  if (responses.ID1 < 50) {
    const yearsUntil50 = 50 - responses.ID1;
    return language === 'fr' ? 
      `Non éligible actuellement (âge < 50). Commencer le dépistage par mammographie dans ${yearsUntil50} ans (à 50 ans)` : 
      `Not currently eligible (age < 50). Initiate mammogram screening in ${yearsUntil50} years (at age 50)`;
  }
  
  if (responses.ID1 > 74) {
    return language === 'fr' ? "Aucun dépistage supplémentaire nécessaire (âge > 74)" : "No further screening needed (age > 74)";
  }
  
  if (responses.ID1 >= 50 && responses.ID1 <= 74) {
    if (responses.ID6a === 'Non') {
      return language === 'fr' ? "Offrir une mammographie maintenant (50-74 ans, aucune mammographie précédente)" : "Offer mammogram now (age 50-74, no previous mammogram)";
    } else if (responses.ID7 === 'Oui') {
      return language === 'fr' ? "Offrir une mammographie maintenant (50-74 ans, dernière mammographie il y a > 2 ans)" : "Offer mammogram now (age 50-74, last mammogram > 2 years ago)";
    } else if (responses.ID7 === 'Non') {
      return language === 'fr' ? "Offrir une mammographie 2 ans après la dernière (50-74 ans, dernière mammographie il y a < 2 ans)" : "Offer mammogram 2 years after last (age 50-74, last mammogram < 2 years ago)";
    }
  }
  
  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getColorectalCancerRecommendation = (responses, language) => {
  if (responses.ID3.includes('Cancer du côlon ou polypes stade avancé') || 
      responses.ID3.includes('Colorectal cancer or advanced polyps') ||
      responses.ID3.includes('Syndrome de Lynch') || 
      responses.ID3.includes('Lynch syndrome') ||
      responses.ID3.includes('Polypose adénomateuse familiale') ||
      responses.ID3.includes('Familial adenomatous polyposis') ||
      responses.ID5.includes('Cancer colorectal ou polypes') ||
      responses.ID5.includes('Colorectal cancer or polyps') ||
      responses.ID5.includes('Maladie inflammatoire de l\'intestin') ||
      responses.ID5.includes('Inflammatory bowel disease')) {
    let justification = language === 'fr' ? "Une évaluation plus approfondie est nécessaire en raison de " : "Further evaluation needed due to ";
    let reasons = [];

    if (responses.ID3.includes('Cancer du côlon ou polypes stade avancé') || 
        responses.ID3.includes('Colorectal cancer or advanced polyps') ||
        responses.ID3.includes('Syndrome de Lynch') || 
        responses.ID3.includes('Lynch syndrome') ||
        responses.ID3.includes('Polypose adénomateuse familiale') ||
        responses.ID3.includes('Familial adenomatous polyposis')) {
      reasons.push(language === 'fr' ? "antécédents familiaux de " : "family history of " + 
        responses.ID3.filter(item => 
          ['Cancer du côlon ou polypes stade avancé', 'Colorectal cancer or advanced polyps', 'Syndrome de Lynch', 'Lynch syndrome', 'Polypose adénomateuse familiale', 'Familial adenomatous polyposis'].includes(item)
        ).join(", "));
    }
    if (responses.ID5.includes('Cancer colorectal ou polypes') ||
        responses.ID5.includes('Colorectal cancer or polyps') ||
        responses.ID5.includes('Maladie inflammatoire de l\'intestin') ||
        responses.ID5.includes('Inflammatory bowel disease')) {
      if (reasons.length > 0) justification += language === 'fr' ? " ET " : " AND ";
      reasons.push(language === 'fr' ? "antécédents personnels de " : "personal history of " + 
        responses.ID5.filter(item => 
          ['Cancer colorectal ou polypes', 'Colorectal cancer or polyps', 'Maladie inflammatoire de l\'intestin', 'Inflammatory bowel disease'].includes(item)
        ).join(", "));
    }
    justification += reasons.join(language === 'fr' ? " ET " : " AND ");
    return justification;
  }

  if (responses.ID1 < 50) {
    const yearsUntil50 = 50 - responses.ID1;
    return language === 'fr' ? 
      `Non éligible actuellement (âge < 50). Lancer le test FIT dans ${yearsUntil50} ans (à 50 ans)` : 
      `Not currently eligible (age < 50). Initiate FIT test in ${yearsUntil50} years (at age 50)`;
  }

  if (responses.ID1 > 74) {
    return language === 'fr' ? "Aucun dépistage supplémentaire nécessaire (âge >74)" : "No further screening needed (age >74)";
  }

  if (responses.ID8 === 'Oui' || responses.ID8 === 'Yes') {
    return language === 'fr' ? 
      "Offrir un test FIT 10 ans après la dernière coloscopie si le patient est âgé de 74 ans ou moins" : 
      "Offer FIT test 10 years after last colonoscopy if patient will be 74 years old or younger";
  }

  if (responses.ID9 === 'Oui' || responses.ID9 === 'Yes') {
    return language === 'fr' ? 
      "Offrir un test FIT 2 ans après le dernier (50-74 ans, dernier FIT il y a < 2 ans)" : 
      "Offer FIT test 2 years after last (age 50-74, last FIT < 2 years ago)";
  } else if (responses.ID9 === 'Non' || responses.ID9 === 'No') {
    return language === 'fr' ? 
      "Offrir le test RSOS maintenant (50-74 ans, pas de test RSOS au cours des 2 dernières années)" : 
      "Offer FIT test now (age 50-74, no FIT test within last 2 years)";
  }
  
  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getCervicalCancerRecommendation = (responses, language) => {
  if (responses.ID2 === 'Homme' || responses.ID2 === 'Male') {
    return language === 'fr' ? "Aucun test recommandé" : "No testing recommended";
  }

  if (responses.ID5.includes("Cancer du col de l'utérus") || 
      responses.ID5.includes("Cervical cancer") || 
      responses.ID13 === 'Oui' || responses.ID13 === 'Yes' || 
      responses.ID14 === 'Oui' || responses.ID14 === 'Yes' ||
      responses.ID11 === 'Oui' || responses.ID11 === 'Yes') {
    let justification = language === 'fr' ? "Évaluation plus approfondie nécessaire en raison de " : "Further evaluation needed due to ";
    let reasons = [];

    if (responses.ID5.includes("Cancer du col de l'utérus") || responses.ID5.includes("Cervical cancer")) {
      reasons.push(language === 'fr' ? "l'antécédent personnel de cancer du col de l'utérus" : "personal history of cervical cancer");
    }
    if (responses.ID13 === 'Oui' || responses.ID13 === 'Yes') {
      reasons.push(language === 'fr' ? "système immunitaire affaibli" : "weakened immune system");
    }
    if (responses.ID14 === 'Oui' || responses.ID14 === 'Yes') {
      reasons.push(language === 'fr' ? "résultat anormal d'un test Pap antérieur" : "previous abnormal Pap test result");
    }
    if (responses.ID11 === 'Oui' || responses.ID11 === 'Yes') {
      reasons.push(language === 'fr' ? "antécédents de radiothérapie abdominale" : "history of abdominal radiation");
    }

    justification += reasons.join(language === 'fr' ? " ET " : " AND ");
    return justification;
  }

  if (responses.ID1 < 25 && (responses.ID2 === 'Femme' || responses.ID2 === 'Female') && (responses.ID10 === 'Non' || responses.ID10 === 'No')) {
    const yearsUntil25 = 25 - responses.ID1;
    return language === 'fr' ? 
      `Non éligible actuellement (âge < 25). Commencer le dépistage dans ${yearsUntil25} ans (à 25 ans) si vous êtes active sexuellement` : 
      `Not currently eligible (age < 25). Initiate screening in ${yearsUntil25} years (at age 25) if sexually active`;
  }
  if (responses.ID1 >= 25 && (responses.ID2 === 'Femme' || responses.ID2 === 'Female') && (responses.ID10 === 'Non' || responses.ID10 === 'No')) {
    return language === 'fr' ? "Non éligible actuellement (jamais active sexuellement)" : "Not currently eligible (never sexually active)";
  }
  if (responses.ID12 === 'Oui' || responses.ID12 === 'Yes') {
    return language === 'fr' ? "Aucun autre dépistage n'est nécessaire (hystérectomie totale)" : "No further screening needed (total hysterectomy)";
  }
  if (responses.ID1 >= 25 && responses.ID1 <= 69 && (responses.ID15 === "Il y a plus de 3 ans" || responses.ID15 === "More than 3 years ago" || responses.ID15 === "Jamais" || responses.ID15 === "Never")) {
    return language === 'fr' ? 
      "Offrir un test Pap maintenant (25 à 69 ans, sexuellement actif, aucun critère d'exclusion, dernier test Pap il y a > 3 ans)" : 
      "Offer Pap test now (age 25-69, sexually active, no exclusion criteria, last Pap test > 3 years ago)";
  }
  if (responses.ID1 >= 25 && responses.ID1 <= 69 && (responses.ID15 === "Au cours des 3 dernières années" || responses.ID15 === "Within the last 3 years")) {
    return language === 'fr' ? 
      "Offrir un test Pap 3 ans après le dernier test Pap (25 à 69 ans, sexuellement actif, aucun critère d'exclusion, dernier test Pap il y a < 3 ans)" : 
      "Offer Pap 3 years after last Pap test (age 25-69, sexually active, no exclusion criteria, last Pap test < 3 years ago)";
  }
  if (responses.ID1 >= 70 && (responses.ID15a === 'Oui' || responses.ID15a === 'Yes')) {
    return language === 'fr' ? 
      "Aucun autre dépistage n'est nécessaire (âge > 69 ans, ayant subi 3 tests Pap négatifs successifs au cours des 10 dernières années)" : 
      "No further screening needed (age > 69, given 3 successive negative Pap tests in the last 10 years)";
  }

  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getDyslipidemiaRecommendation = (responses, language) => {
  if (responses.ID5.includes(language === 'fr' ? 'Taux de cholestérol élevé' : 'High cholesterol')) {
    return language === 'fr' ? 
      "Une évaluation plus approfondie est nécessaire (antécédents personnels de dyslipidémie)" : 
      "Further evaluation needed (personal history of dyslipidemia)";
  }
  
  if (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male') && responses.ID1 < 40) {
    return language === 'fr' ? 
      "Non éligible actuellement au NFLP (âge < 40)" : 
      "Not currently eligible (age < 40)";
  }
  if (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 < 50) {
    return language === 'fr' ? 
      "Non éligible actuellement au NFLP (âge < 50)" : 
      "Not currently eligible (age < 50)";
  }
  
  if (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male') && responses.ID1 >= 40 && (responses.ID16 === (language === 'fr' ? 'Non' : 'No') || (responses.ID16 === (language === 'fr' ? 'Oui' : 'Yes') && responses.ID17 === (language === 'fr' ? 'Oui' : 'Yes')))) {
    return language === 'fr' ? 
      "Offrir NFLP maintenant (âge > 40 ans, la dernière fois remonte à > 5 ans OU jamais fait)" : 
      "Offer NFLP now (age > 40, last was > 5 years ago OR never done)";
  }
  if (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 50 && (responses.ID16 === (language === 'fr' ? 'Non' : 'No') || (responses.ID16 === (language === 'fr' ? 'Oui' : 'Yes') && responses.ID17 === (language === 'fr' ? 'Oui' : 'Yes')))) {
    return language === 'fr' ? 
      "Offrir un bilan lipidique (âge > 50 ans, la dernière fois remonte à > 5 ans ou jamais fait)" : 
      "Offer NFLP now (age > 50, last was > 5 years ago OR never done)";
  }
  
  if (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male') && responses.ID1 >= 40 && responses.ID16 === (language === 'fr' ? 'Oui' : 'Yes') && responses.ID17 === (language === 'fr' ? 'Non' : 'No')) {
    return language === 'fr' ? 
      "Offrez le NFLP 5 ans après le dernier (âge > 40 ans, dernier NFLP il y a < 5 ans)" : 
      "Offer NFLP 5 years after last (age > 40, last NFLP < 5 years ago)";
  }
  if (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 50 && responses.ID16 === (language === 'fr' ? 'Oui' : 'Yes') && responses.ID17 === (language === 'fr' ? 'Non' : 'No')) {
    return language === 'fr' ? 
      "Offrez le NFLP 5 ans après le dernier (âge > 50 ans, dernier NFLP il y a < 5 ans)" : 
      "Offer NFLP 5 years after last (age > 50, last NFLP < 5 years ago)";
  }
  
  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getSTIsRecommendation = (responses, language) => {
  if (responses.ID1 >= 30) {
    return language === 'fr' ? 
      "Aucun dépistage de routine nécessaire (âge > 30)" : 
      "No further routine screening needed (age > 30)";
  }
  
  if (responses.ID1 >= 18 && responses.ID1 <= 29) {
    if (responses.ID10 === (language === 'fr' ? 'Non' : 'No')) {
      return language === 'fr' ? 
        "Non éligible actuellement (jamais sexuellement actif)" : 
        "Not currently eligible (never sexually active)";
    } else if (responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes')) {
      if (responses.ID10a === (language === 'fr' ? 'Non' : 'No')) {
        return language === 'fr' ? 
          "Offrir un test G/C maintenant (18-30 ans, dernier G/C il y a >1 an)" : 
          "Offer G/C test now (age 18-30, last G/C >1 year ago)";
      } else if (responses.ID10a === (language === 'fr' ? 'Oui' : 'Yes')) {
        return language === 'fr' ? 
          "Offrir un test G/C 1 an après le dernier (18-30 ans, dernier G/C il y a moins d'un an)" : 
          "Offer G/C test 1 year after last (age 18-30, last G/C <1 year ago)";
      }
    }
  }
  
  return language === 'fr' ? 
    "Parlez à votre professionnel de la santé" : 
    "Speak to your healthcare provider";
};

const getAAARecommendation = (responses, language) => {
  if (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female')) {
    return language === 'fr' ? "Aucun dépistage recommandé" : "No testing recommended";
  }
  if (responses.ID1 < 65) {
    const yearsUntil65 = 65 - responses.ID1;
    return language === 'fr' ? 
      `Non éligible actuellement (âge < 65). Offrir un dépistage dans ${yearsUntil65} ans (à 65 ans)` : 
      `Not currently eligible (age < 65). Offer screening in ${yearsUntil65} years (at age 65)`;
  }
  
  if (responses.ID1 > 80) {
    return language === 'fr' ? "Aucun dépistage supplémentaire nécessaire (âge > 80)" : "No further screening needed (age > 80)";
  }
  
  if (responses.ID1 >= 65 && responses.ID1 <= 80 && (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male'))) {
    if (responses.ID38 === (language === 'fr' ? 'Non' : 'No')) {
      return language === 'fr' ? 
        "Offrir dès maintenant une échographie abdominale (65-80 ans, pas d'échographie abdominale antérieure)" : 
        "Offer abdominal U/S now (age 65-80, no prior abdominal U/S)";
    } else if (responses.ID38 === (language === 'fr' ? 'Oui' : 'Yes')) {
      return language === 'fr' ? 
        "Évaluation supplémentaire nécessaire (échographie abdominale antérieure signalée)" : 
        "Further evaluation needed (reported prior abdominal ultrasound)";
    }
  }
  
  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getFolicAcidRecommendation = (responses, language) => {
  if (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male')) {
    return language === 'fr' ? "Aucune supplémentation en acide folique recommandée" : "No folic acid supplementation recommended";
  }

  if (responses.ID1 < 18) {
    return language === 'fr' ? "Aucune supplémentation en acide folique recommandée" : "No folic acid supplementation recommended";
  }

  if (responses.ID1 >= 18 && responses.ID1 <= 55) {
    return language === 'fr' ? "Supplémentation quotidienne en acide folique de 400 mcg recommandée (18-55 ans)" : "Daily folic acid supplementation of 400 mcg recommended (age 18-55)";
  }

  if (responses.ID1 > 55) {
    return language === 'fr' ? "Aucune supplémentation en acide folique recommandée (âge > 55 ans)" : "No folic acid supplementation recommended (age > 55)";
  }

  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getLungCancerRecommendation = (responses, language) => {
  if (responses.ID5.includes(language === 'fr' ? 'Cancer du poumon' : 'Lung cancer')) {
    return language === 'fr' ? "Une évaluation plus approfondie est nécessaire étant donné les antécédents personnels de cancer du poumon" : "Further evaluation needed given personal history of lung cancer";
  }

  if (responses.ID1 < 55) {
    const yearsUntil55 = 55 - responses.ID1;
    return language === 'fr' ? `Non éligible actuellement (âge < 55 ans). Répéter le questionnaire dans ${yearsUntil55} ans (à 55 ans)` : `Not currently eligible (age < 55). Repeat questionnaire in ${yearsUntil55} years (at age 55)`;
  }
  
  if (responses.ID1 > 74) {
    return language === 'fr' ? "Aucun dépistage supplémentaire nécessaire (âge > 74)" : "No further screening needed (age > 74)";
  }
  
  if ((responses.ID18 === 'Non' || responses.ID18 === 'No') && 
      (responses.ID19 === 'Oui' || responses.ID19 === 'Yes' || responses.ID19 === 'Jamais fumé' || responses.ID19 === 'Never smoked')) {
    return language === 'fr' ? "Non éligible (jamais fumeur ou arrêté il y a > 15 ans)" : "Not eligible (never-smoker, or quit > 15 years ago)";
  }

  if ((responses.ID18 === 'Oui' || responses.ID18 === 'Yes') || 
      ((responses.ID18 === 'Non' || responses.ID18 === 'No') && (responses.ID19 === 'Non' || responses.ID19 === 'No'))) {
    const packYears = responses.ID20 * responses.ID21;
    if (packYears < 30) {
      return language === 'fr' ? "Non éligible (historique < 30 paquet-années)" : "Not eligible (< 30 pack-year history)";
    } else if (packYears >= 30) {
      if (responses.ID21a === 'Oui' || responses.ID21a === 'Yes') {
        return language === 'fr' ? "Non éligible (terminé la série de 3 tomodensitométries à faible dose)" : "Not eligible (completed the series of 3 low-dose CT scans)";
      } else if (responses.ID21a === 'Non' || responses.ID21a === 'No') {
        return language === 'fr' ? "Offrir une tomodensitométrie à faible dose (55 à 74 ans, > 30 paquet-années, fumeur ou a arrêté il y a moins de 15 ans, n'a pas complété une série de 3 tomodensitométries à faible dose)" : "Offer low-dose CT scan now (age 55-74, >30PY, smoker or quit <15 years ago, did not complete series of 3 low-dose CT scans)";
      }
    }
  }
  
  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getDiabetesRecommendation = (responses, findRiscScore, language) => {
  if (responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes')) {
    return language === 'fr' ? "Une évaluation plus approfondie est nécessaire (antécédents personnels de diabète)" : "Further evaluation needed (personal history of diabetes)";
  }

  if (responses.ID1 < 18) {
    const yearsUntil18 = 18 - responses.ID1;
    return language === 'fr' ? 
      `Aucun dépistage recommandé, répéter le questionnaire dans ${yearsUntil18} ans (à l'âge de 18 ans)` : 
      `No screening recommended, repeat questionnaire in ${yearsUntil18} years (at age 18)`;
  }

  if (findRiscScore < 15) {
    return language === 'fr' ? 
      "Aucun test d'HbA1c recommandé (score FINDRISC 0-14). Requestionner dans un an" : 
      "No A1C test recommended (FINDRISC score 0-14). Repeat questionnaire in one year";
  }

  if (findRiscScore >= 15 && findRiscScore <= 20) {
    if (responses.ID37 === 'never' || responses.ID37 === 'moreThan3years') {
      return language === 'fr' ? 
        "Offrir un test d'HbA1c maintenant (score FINDRISC 15-20, dernier test d'HbA1c il y a plus de 3 ans)" : 
        "Offer A1C now (FINDRISC score 15-20, last A1C >3 years ago)";
    } else if (responses.ID37 === 'last3years') {
      return language === 'fr' ? 
        "Offrir un test d'HbA1c 3 ans après le dernier test (score FINDRISC 15-20, dernier test d'HbA1c il y a moins de 3 ans)" : 
        "Offer A1C 3 years after last A1C (FINDRISC score 15-20, last A1C <3 years ago)";
    }
  }

  if (findRiscScore >= 21) {
    if (responses.ID37 === 'never' || responses.ID37 === 'moreThan1year') {
      return language === 'fr' ? 
        "Offrir un test d'HbA1c maintenant (score FINDRISC 21-30, dernier test d'HbA1c il y a plus d'un an)" : 
        "Offer A1C now (FINDRISC score 21-30, last A1C >1 year ago)";
    } else if (responses.ID37 === 'last1year') {
      return language === 'fr' ? 
        "Offrir un test d'HbA1c 1 an après le dernier test (score FINDRISC 21-30, dernier test d'HbA1c il y a moins d'un an)" : 
        "Offer A1C 1 year after last A1C (FINDRISC score 21-30, last A1C <1 year ago)";
    }
  }

  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getZosterRecommendation = (responses, language) => {
  if (responses.ID13 === 'Yes' || responses.ID13 === 'Oui') {
    return language === 'fr' ? 
      "Offrir le vaccin gratuit contre le zona (âge > 18, immunosupprimé)" : 
      "Offer free shingles vaccine now (age >18, immunosuppressed)";
  }

  if (responses.ID1 >= 75) {
    return language === 'fr' ? 
      "Offrir le vaccin gratuit contre le zona (âge > 75)" : 
      "Offer free shingles vaccine now (age > 75)";
  }

  if (responses.ID1 >= 50 && responses.ID1 < 75) {
    return language === 'fr' ? 
      "Offrir le vaccin contre le zona, non-gratuit (âge 50-75)" : 
      "Offer shingles vaccine now (to be paid out-of-pocket) (age 50-75)";
  }

  if (responses.ID1 < 50 && (responses.ID13 === 'No' || responses.ID13 === 'Non')) {
    return language === 'fr' ? 
      "Non éligible pour le vaccin contre le zona (âge < 50)" : 
      "Not currently eligible (age < 50)";
  }

  return language === 'fr' ? 
    "Parlez à votre professionnel de la santé" : 
    "Speak to your healthcare provider";
};

const getPneumococcusRecommendation = (responses, language) => {
  if (responses.ID1 >= 65) {
    if (responses.ID26 === 'Yes' || responses.ID26 === 'Oui') {
      return language === 'fr' ? 
        "Aucune autre vaccination nécessaire (déjà vacciné contre le pneumocoque)" : 
        "No further vaccination needed (already vaccinated against pneumococcus)";
    } else if (responses.ID26 === 'No' || responses.ID26 === 'Non') {
      return language === 'fr' ? 
        "Offrir le vaccin contre le pneumocoque maintenant (âge > 65 ans et pas encore vacciné)" : 
        "Offer pneumococcus vaccine now (age > 65 and not yet vaccinated)";
    }
  } else {
    return language === 'fr' ? 
      "Non éligible (âge < 65 ans)" : 
      "Not currently eligible (age < 65)";
  }
};

const getTetanusRecommendation = (responses, language) => {
  if (responses.ID1 < 50) {
    return language === 'fr' ? 
      "Non éligible actuellement pour le rappel de vaccin Td (âge < 50 ans)" : 
      "Not currently eligible for Td booster (age < 50 years)";
  } else {
    if (responses.ID24 === 'Non' || responses.ID24 === 'No') {
      return language === 'fr' ? 
        "Offrir un rappel de Td (âge > 50 ans et n'a pas reçu le rappel)" : 
        "Offer Td booster now (age > 50 and has not received the booster)";
    } else if (responses.ID24 === 'Oui' || responses.ID24 === 'Yes') {
      return language === 'fr' ? 
        "Aucun autre dépistage Td nécessaire (a déjà reçu un rappel)" : 
        "No further Td screening needed (already received booster)";
    }
  }
  return language === 'fr' ? 
    "Parlez-en à votre professionnel de la santé" : 
    "Speak to your healthcare provider";
};

const getHypertensionRecommendation = (responses, language) => {
  if (responses.ID5.includes(language === 'fr' ? 'Hypertension' : 'Hypertension')) {
    return language === 'fr' ? 
      "Une évaluation plus approfondie est nécessaire (antécédents personnels d'hypertension)" : 
      "Further evaluation needed (personal history of hypertension)";
  }
  return language === 'fr' ? 
    "Offrir un contrôle annuel de la pression artérielle (âge > 18 ans)" : 
    "Offer BP check now (age > 18)";
};

const getFractureRecommendation = (responses, language) => {
  if (responses.ID5.includes(language === 'fr' ? 'Fracture de fragilité (ostéoporose)' : 'Fragility fracture (osteoporosis)')) {
    return language === 'fr' ? 
      "Évaluation plus approfondie nécessaire en raison de l'historique personnel d'ostéoporose" : 
      "Further evaluation needed due to personal history of osteoporosis";
  }

  if (responses.ID2 === (language === 'fr' ? 'Homme' : 'Male')) {
    return language === 'fr' ? "Aucun dépistage recommandé" : "No screening recommended";
  }

  if (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 < 65) {
    const yearsUntil65 = 65 - responses.ID1;
    return language === 'fr' ? 
      `Non éligible actuellement (femmes < 65 ans). Répéter le questionnaire dans ${yearsUntil65} ans (à l'âge de 65 ans)` : 
      `Not currently eligible (women < 65). Repeat questionnaire in ${yearsUntil65} years (at age 65)`;
  }

  if (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 65) {
    const fraxData = `
    ${language === 'fr' ? 'Âge' : 'Age'}: ${responses.ID1},
    ${language === 'fr' ? 'Sexe' : 'Sex'}: ${responses.ID2},
    ${language === 'fr' ? 'Poids' : 'Weight'}: ${responses.ID31} kg,
    ${language === 'fr' ? 'Taille' : 'Height'}: ${responses.ID30} cm,
    ${language === 'fr' ? 'Polyarthrite rhumatoïde' : 'Rheumatoid arthritis'}: ${responses.ID5.includes(language === 'fr' ? 'Polyarthrite rhumatoïde' : 'Rheumatoid arthritis')},
    ${language === 'fr' ? 'Ostéoporose secondaire' : 'Secondary osteoporosis'}: ${responses.ID5.includes(language === 'fr' ? 'Ostéoporose secondaire' : 'Secondary osteoporosis')},
    ${language === 'fr' ? 'Fracture personnelle' : 'Personal fracture'}: ${responses.ID27},
    ${language === 'fr' ? 'Fracture de la hanche parentale' : 'Parental hip fracture'}: ${responses.ID28},
    ${language === 'fr' ? 'Utilisation actuelle de stéroïdes' : 'Current steroid use'}: ${responses.ID29},
    ${language === 'fr' ? 'Tabagisme actuel' : 'Current smoking'}: ${responses.ID18},
    ${language === 'fr' ? 'Consommation d\'alcool > 3 unités/jour' : 'Alcohol intake > 3 units/day'}: ${responses.ID22}`;

    return language === 'fr' ? 
      `Calculer le score FRAX clinique canadien en utilisant les données suivantes du patient : ${fraxData}` : 
      `Calculate Canadian clinical FRAX score using the following patient data: ${fraxData}`;
  }

  return language === 'fr' ? "Parlez à votre professionnel de la santé" : "Speak to your healthcare provider";
};

const getScreeningRecommendations = (responses, findRiscScore, language) => {
  const recommendations = {
    [language === 'fr' ? 'Cancer du sein' : 'Breast cancer']: getBreastCancerRecommendation(responses, language),
    [language === 'fr' ? 'Cancer colorectal' : 'Colorectal cancer']: getColorectalCancerRecommendation(responses, language),
    [language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer']: getCervicalCancerRecommendation(responses, language),
    [language === 'fr' ? 'Cancer du poumon' : 'Lung cancer']: getLungCancerRecommendation(responses, language),
    'AAA': getAAARecommendation(responses, language),
    [language === 'fr' ? 'Acide folique' : 'Folic acid']: getFolicAcidRecommendation(responses, language),
    [language === 'fr' ? 'Hypertension' : 'Hypertension']: getHypertensionRecommendation(responses, language),
    [language === 'fr' ? 'Dyslipidémie' : 'Dyslipidemia']: getDyslipidemiaRecommendation(responses, language),
    [language === 'fr' ? 'Risque de fracture de fragilité' : 'Fragility fracture risk']: getFractureRecommendation(responses, language),
    [language === 'fr' ? 'Diabète' : 'Diabetes']: getDiabetesRecommendation(responses, findRiscScore, language),
    [language === 'fr' ? 'ITSS' : 'STIs']: getSTIsRecommendation(responses, language),
    [language === 'fr' ? 'Vaccin contre la grippe' : 'Flu vaccine']: language === 'fr' ? "Offrir le vaccin contre la grippe saisonnière maintenant (âge > 18)" : "Offer seasonal influenza vaccine now (age > 18)",
    [language === 'fr' ? 'Vaccin contre le zona' : 'Zoster vaccine']: getZosterRecommendation(responses, language),
    [language === 'fr' ? 'Vaccin contre le pneumocoque' : 'Pneumococcus vaccine']: getPneumococcusRecommendation(responses, language),
    [language === 'fr' ? 'Rappel contre le tétanos' : 'Tetanus booster']: getTetanusRecommendation(responses, language),
  };

  return recommendations;
};

const getSmokingStatus = (responses, language) => {
  if (responses.ID18 === 'Oui' || responses.ID18 === 'Yes') {
    return language === 'fr' ? 'Fumeur actuel' : 'Current smoker';
  } else if (responses.ID18 === 'Non' || responses.ID18 === 'No') {
    if (responses.ID19 === 'Non' || responses.ID19 === 'No') {
      return language === 'fr' ? 'Arrêt < 15 ans' : 'Quit < 15 years ago';
    } else if (responses.ID19 === 'Oui' || responses.ID19 === 'Yes') {
      return language === 'fr' ? 'Arrêt > 15 ans' : 'Quit > 15 years ago';
    } else if (responses.ID19 === 'Jamais fumé' || responses.ID19 === 'Never smoked') {
      return language === 'fr' ? 'Non-fumeur' : 'Never smoker';
    }
  }
  return language === 'fr' ? 'Statut de fumeur inconnu' : 'Unknown smoking status';
};

const getAlcoholStatus = (responses, language) => {
  if (responses.ID22 === 'Oui' || responses.ID22 === 'Yes') {
    return language === 'fr' ? 'EtOH: > 3 verres/jour' : 'EtOH: > 3 drinks/day';
  } else if (responses.ID22 === 'Non' || responses.ID22 === 'No') {
    return language === 'fr' ? 'EtOH: < 3 verres/jour' : 'EtOH: < 3 drinks/day';
  } else {
    return language === 'fr' ? 'EtOH: Statut inconnu' : 'EtOH: Unknown status';
  }
};

function DynamicQuestionnaire({ onIntroChange }) {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'en' ? 'fr' : 'en');
  };

  const [responses, setResponses] = useState({
    ID1: null,
    ID2: null,
    ID3: [],
    ID4: null,
    ID5: [],
    ID6: null,
    ID6a: null,
    ID7: null,
    ID8: null,
    ID9: null,
    ID10: null,
    ID10a: null,
    ID11: null,
    ID12: null,
    ID13: null,
    ID14: null,
    ID15: null,
    ID15a: null,
    ID16: null,
    ID17: null,
    ID18: null,
    ID19: null,
    ID20: null,
    ID21: null,
    ID21a: null,
    ID22: null,
    ID24: null,
    ID25: null,
    ID26: null,
    ID27: null,
    ID28: null,
    ID29: null,
    ID30: null,
    ID31: null,
    // ID32: null,
    ID33: null,
    ID34: null,
    ID35: null,
    ID36: null,
    ID37: null,
    ID38: null,
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showIntro, setShowIntro] = useState(true);
  const [showPDF, setShowPDF] = useState(false);
  const [findRiscScore, setFindRiscScore] = useState(0);

  useEffect(() => {
    onIntroChange(showIntro);
  }, [showIntro, onIntroChange]);

  // New useEffect hook to log responses
  useEffect(() => {
    console.log('Current responses:', responses);
    const recommendations = getScreeningRecommendations(responses, findRiscScore, language);
    console.log('Current screening recommendations:', recommendations);
  }, [responses, findRiscScore]);

  useEffect(() => {
    updateFindRiscScore(responses);
  }, [responses]);

  const handleInputChange = (id, value) => {
    setResponses(prevResponses => {
      const newResponses = { ...prevResponses, [id]: value };
      updateFindRiscScore(newResponses);
      return newResponses;
    });
  };

  const updateFindRiscScore = (newResponses) => {
    let score = 3;

    // Age
    if (newResponses.ID1 >= 45 && newResponses.ID1 <= 54) score += 2;
    else if (newResponses.ID1 >= 55 && newResponses.ID1 <= 64) score += 3;
    else if (newResponses.ID1 > 64) score += 4;

    // BMI
    if (newResponses.ID30 && newResponses.ID31) {
      const heightInMeters = newResponses.ID30 / 100;
      const bmi = newResponses.ID31 / (heightInMeters * heightInMeters);
      console.log('Current BMI:', bmi.toFixed(2));
      if (bmi > 25 && bmi <= 30) score += 1;
      else if (bmi > 30) score += 3;
    }

    // Waist circumference
    // if (newResponses.ID32) {
    //   if (newResponses.ID2 === 'Male') {
    //     if (newResponses.ID32 >= 94 && newResponses.ID32 < 102) score += 3;
    //     else if (newResponses.ID32 >= 102) score += 4;
    //   } else if (newResponses.ID2 === 'Female') {
    //     if (newResponses.ID32 >= 80 && newResponses.ID32 < 88) score += 3;
    //     else if (newResponses.ID32 >= 88) score += 4;
    //   }
    // }

    // Blood pressure medication
    if (newResponses.ID35 === 'Yes') score += 2;

    // History of high blood glucose
    if (newResponses.ID36 === 'Yes') score += 5;

    // Physical activity
    if (newResponses.ID33 === 'No') score += 2;

    // Daily consumption of vegetables, fruits, or berries
    if (newResponses.ID34 === 'Not every day') score += 1;

    // Family history of diabetes
    if (newResponses.ID4 === 'Yes (grandparent, uncle/aunt, or first cousin)') score += 3;
    else if (newResponses.ID4 === 'Yes (parent, sibling, or child)') score += 5;

    setFindRiscScore(score);
    console.log('Current FINDRISC Score:', score);
  };

  const questions = [
    {
      id: 'ID1',
      label: language === 'fr' ? "Quel âge avez-vous ?" : "How old are you?",
      render: () => (
        <div className="flex flex-col items-center">
          <div className="mb-4">
            {responses.ID1 && responses.ID1 >= 18 ? (
              responses.ID1 < 75 ? (
                <AdultSVG />
              ) : (
                <SeniorSVG />
              )
            ) : (
              <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                ?
              </div>
            )}
          </div>
          <Input
            id="ID1"
            type="number"
            value={responses.ID1 || ''}
            onChange={(e) => {
              const value = e.target.value === '' ? '' : parseInt(e.target.value);
              handleInputChange('ID1', value);
            }}
            placeholder={language === 'fr' ? "Entrez votre âge" : "Enter your age"}
            min="18"
            className="w-full max-w-xs"
          />
          {(responses.ID1 === '' || isNaN(responses.ID1) || responses.ID1 < 18) && (
            <p className="text-red-500 text-sm mt-1">
              {language === 'fr' ? "Veuillez entrer un âge valide de 18 ans ou plus." : "Please enter a valid age of 18 or older."}
            </p>
          )}
        </div>
      ),
      condition: true,
    },
    {
      id: 'ID2',
      label: language === 'fr' ? 'Quel sexe vous a été assigné à la naissance ?' : 'What sex were you assigned at birth?',
      render: () => (
        <div className="flex flex-col items-center">
          <div className="mb-4">
            {responses.ID1 && responses.ID1 >= 18 && (
              responses.ID1 < 75 ? (
                <AdultSVG />
              ) : (
                <SeniorSVG />
              )
            )}
          </div>
          <Select value={responses.ID2 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID2', value)}>
            <SelectTrigger id="ID2">
              <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
              <SelectItem value={language === 'fr' ? 'Homme' : 'Male'}>{language === 'fr' ? 'Homme' : 'Male'}</SelectItem>
              <SelectItem value={language === 'fr' ? 'Femme' : 'Female'}>{language === 'fr' ? 'Femme' : 'Female'}</SelectItem>
            </SelectContent>
          </Select>
        </div>
      ),
      condition: responses.ID1 >= 18,
      validate: () => responses.ID2 !== null && responses.ID2 !== (language === 'fr' ? 'Sélectionner' : 'Select'),
    },
    {
      id: 'transitionSlide',
      label: language === 'fr' ? "Excellent ! 🎉\nMaintenant, quelques questions sur la santé de vos proches. Cela nous aidera à comprendre les risques pour votre santé." : "Great job!🎉\nNext up: your family's health. This helps us understand any potential health risks that may run in your family.",
      render: () => (
        <div className="transition-slide">
          <p>{questions.find(q => q.id === 'transitionSlide').label.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}</p>
        </div>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID3',
      label: language === 'fr' ? "Est-ce qu'un membre de votre famille proche (parent, frère/sœur, enfant) a déjà reçu un diagnostic de :" : "Has anyone in your immediate family (parent, sibling, child) ever been diagnosed with:",
      render: () => (
        <div className="space-y-2 mt-2">
          {[
            language === 'fr' ? "Cancer du sein" : "Breast cancer",
            language === 'fr' ? "Cancer du côlon ou polypes stade avancé" : "Colon cancer or advanced polyps",
            language === 'fr' ? "Syndrome de Lynch" : "Lynch syndrome",
            language === 'fr' ? "Polypose adénomateuse familiale" : "Familial adenomatous polyposis",
            language === 'fr' ? "Mutation BRCA1/2" : "BRCA1/2 mutation"
          ].map((condition) => (
            <div key={condition} className="flex items-center space-x-2">
              <Checkbox
                id={`ID3-${condition}`}
                checked={responses.ID3.includes(condition)}
                onCheckedChange={(checked) => {
                  const newValue = checked
                    ? [...responses.ID3, condition]
                    : responses.ID3.filter(c => c !== condition);
                  handleInputChange('ID3', newValue);
                }}
              />
              <label htmlFor={`ID3-${condition}`} className="text-sm cursor-pointer">{condition}</label>
            </div>
          ))}
        </div>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID4',
      label: language === 'fr' ? "Est-ce qu'un membre de votre famille proche a déjà reçu un diagnostic de diabète (type 1 ou 2) ?" : "Has anyone in your family ever been diagnosed with diabetes (type 1 or 2)?",
      render: () => (
        <Select value={responses.ID4 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID4', value)}>
          <SelectTrigger id="ID4">
            <SelectValue placeholder={language === 'fr' ? "Sélectionner" : "Select"} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? "Sélectionner" : "Select"} disabled>{language === 'fr' ? "Sélectionner" : "Select"}</SelectItem>
            <SelectItem value="Yes (parent, sibling, or child)">{language === 'fr' ? "Oui (parent, frère ou sœur ou enfant)" : "Yes (your parent, brother/sister, or child)"}</SelectItem>
            <SelectItem value="Yes (grandparent, uncle/aunt, or first cousin)">{language === 'fr' ? "Oui (grand-parent, oncle/tante ou cousin germain)" : "Yes (your grandparent, uncle/aunt, or first cousin)"}</SelectItem>
            <SelectItem value="No">{language === 'fr' ? "Non" : "No"}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18,
      validate: () => responses.ID4 !== null && responses.ID4 !== (language === 'fr' ? 'Sélectionner' : 'Select'),
    },
    {
      id: 'transitionSlide2',
      label: language === 'fr' ? "Maintenant, quelques questions sur vos antécédants de santé. 🧑‍⚕️" : "Now, a bit about your own health.",
      render: () => (
        <div className="transition-slide">
          <p>{questions.find(q => q.id === 'transitionSlide2').label.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}</p>
        </div>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID5',
      label: language === 'fr' ? "Avez-vous déjà reçu un diagnostic pour l'une des conditions suivantes ?" : "Have you ever been diagnosed with any of the following conditions?",
      render: () => (
        <div className="space-y-2 mt-2">
          {(responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') ?
            [
              language === 'fr' ? "Cancer du sein" : "Breast cancer",
              language === 'fr' ? "Cancer du col de l'utérus" : "Cervical cancer",
              language === 'fr' ? "Fracture de fragilité (ostéoporose)" : "Fragility fracture (osteoporosis)",
              language === 'fr' ? "Ostéoporose secondaire" : "Secondary osteoporosis"
            ] :
            []
          ).concat([
            language === 'fr' ? "Cancer colorectal ou polypes" : "Colorectal cancer or polyps",
            language === 'fr' ? "Cancer du poumon" : "Lung cancer",
            language === 'fr' ? "Hypertension" : "Hypertension",
            language === 'fr' ? "Diabète" : "Diabetes",
            language === 'fr' ? "Cholestérol élevé" : "High cholesterol",
            language === 'fr' ? "Polyarthrite rhumatoïde" : "Rheumatoid arthritis",
            language === 'fr' ? "Maladie inflammatoire de l'intestin" : "Inflammatory bowel disease"
          ]).map((condition) => (
            <div key={condition} className="flex items-center space-x-2">
              <Checkbox
                id={`ID5-${condition}`}
                checked={responses.ID5.includes(condition)}
                onCheckedChange={(checked) => {
                  const newValue = checked
                    ? [...responses.ID5, condition]
                    : responses.ID5.filter(c => c !== condition);
                  handleInputChange('ID5', newValue);
                }}
              />
              <label htmlFor={`ID5-${condition}`} className="text-sm cursor-pointer">{condition}</label>
            </div>
          ))}
        </div>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID6',
      label: language === 'fr' ? "Avez-vous reçu de la radiothérapie (et non une simple radiographie) à la poitrine, soit avant l\'âge de 30 ans ou au cours des 8 dernières années ?" : "Have you had radiation to your chest within the last 8 years or before the age of 30?",
      render: () => (
        <Select value={responses.ID6 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID6', value)}>
          <SelectTrigger id="ID6">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50 && responses.ID1 <= 74 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') &&
        !responses.ID3.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') &&
        !responses.ID3.includes(language === 'fr' ? 'mutation BRCA1/2' : 'BRCA1/2 mutation'),
    },
    {
      id: 'ID6a',
      label: language === 'fr' ? "Avez-vous déjà eu une mammographie ?" : "Have you ever had a mammogram?",
      render: () => (
        <Select value={responses.ID6a || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID6a', value)}>
          <SelectTrigger id="ID6a">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50 && responses.ID1 <= 74 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') &&
        !responses.ID3.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') &&
        !responses.ID3.includes(language === 'fr' ? 'mutation BRCA1/2' : 'BRCA1/2 mutation') &&
        responses.ID6 === (language === 'fr' ? 'Non' : 'No'),
    },
    {
      id: 'ID7',
      label: language === 'fr' ? "Votre dernière mammographie était-il y a plus de 2 ans ?" : "Has it been more than 2 years since your last mammogram?",
      render: () => (
        <Select value={responses.ID7 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID7', value)}>
          <SelectTrigger id="ID7">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50 && responses.ID1 <= 74 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') &&
        !responses.ID3.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer du sein' : 'Breast cancer') &&
        !responses.ID3.includes(language === 'fr' ? 'mutation BRCA1/2' : 'BRCA1/2 mutation') &&
        responses.ID6 === (language === 'fr' ? 'Non' : 'No') &&
        responses.ID6a === (language === 'fr' ? 'Oui' : 'Yes'),
    },
    {
      id: 'ID8',
      label: language === 'fr' ? "Avez-vous eu une coloscopie (examen du côlon) dans les 10 dernières années ?" : "Have you had a colonoscopy within the last 10 years?",
      render: () => (
        <Select value={responses.ID8 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID8', value)}>
          <SelectTrigger id="ID8">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50 && responses.ID1 <= 74 &&
        !responses.ID3.includes(language === 'fr' ? 'Cancer du côlon ou polypes stade avancé' : 'Colorectal cancer or advanced polyps') &&
        !responses.ID3.includes(language === 'fr' ? 'Syndrome de Lynch' : 'Lynch syndrome') &&
        !responses.ID3.includes(language === 'fr' ? 'Polypose adénomateuse familiale' : 'Familial adenomatous polyposis') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer colorectal ou polypes' : 'Colorectal cancer or polyps') &&
        !responses.ID5.includes(language === 'fr' ? 'Maladie inflammatoire de l\'intestin' : 'Inflammatory bowel disease'),
    },
    {
      id: 'ID9',
      label: language === 'fr' ? "Avez-vous fait un test RSOS (recherche de sang caché dans les selles) au cours des 2 dernières années ?" : "Have you had a FIT test (a stool test that checks for hidden blood) within the last 2 years?",
      render: () => (
        <Select value={responses.ID9 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID9', value)}>
          <SelectTrigger id="ID9">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50 && responses.ID1 <= 74 &&
        !responses.ID3.includes(language === 'fr' ? 'Cancer du côlon ou polypes stade avancé' : 'Colorectal cancer or advanced polyps') &&
        !responses.ID3.includes(language === 'fr' ? 'Syndrome de Lynch' : 'Lynch syndrome') &&
        !responses.ID3.includes(language === 'fr' ? 'Polypose adénomateuse familiale' : 'Familial adenomatous polyposis') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer colorectal ou polypes' : 'Colorectal cancer or polyps') &&
        !responses.ID5.includes(language === 'fr' ? 'Maladie inflammatoire de l\'intestin' : 'Inflammatory bowel disease') &&
        responses.ID8 === (language === 'fr' ? 'Non' : 'No'),
    },
    {
      id: 'ID10',
      label: language === 'fr' ? "Avez-vous déjà été sexuellement actif(ve) ?" : "Have you ever been sexually active?",
      render: () => (
        <Select value={responses.ID10 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID10', value)}>
          <SelectTrigger id="ID10">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID10a',
      label: language === 'fr' ? "Dans la dernière année, avez-vous fait un test d'urine pour les infections transmissibles sexuellement et par le sang (ITSS) ?" : "Have you undergone urine testing to screen for sexually transmitted infections (STIs) within the last year?",
      render: () => (
        <Select value={responses.ID10a || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID10a', value)}>
          <SelectTrigger id="ID10a">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18 && responses.ID1 <= 29 && responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes'),
    },
    {
      id: 'ID11',
      label: language === 'fr' ? "Avez-vous eu de la radiothérapie au ventre quand vous étiez enfant à cause d'un cancer ?" : "Did you receive abdominal radiation therapy for childhood cancer?",
      render: () => (
        <Select value={responses.ID11 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID11', value)}>
          <SelectTrigger id="ID11">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 25 && responses.ID1 <= 69 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer'),
    },
    {
      id: 'ID12',
      label: language === 'fr' ? "Avez-vous eu une chirurgie pour enlever tout votre utérus (hystérectomie totale) ?" : "Have you had surgery to remove all of your uterus (total hysterectomy)?",
      render: () => (
        <Select value={responses.ID12 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID12', value)}>
          <SelectTrigger id="ID12">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 25 && responses.ID1 <= 69 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes') &&
        responses.ID11 === (language === 'fr' ? 'Non' : 'No') && !responses.ID5.includes(language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer'),
    },
    {
      id: 'ID13',
      label: language === 'fr' ? "Avez-vous reçu un diagnostic par un médecin d'un système immunitaire affaibli (immunosupprimé) ?" : "Have you been diagnosed by a doctor with a weakened immune system (immunosuppressed)?",
      render: () => (
        <Select value={responses.ID13 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID13', value)}>
          <SelectTrigger id="ID13">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID14',
      label: language === 'fr' ? "Avez-vous déjà eu un résultat anormal au frottis cervico-utérin (test Pap) ?" : "Have you ever had an abnormal Pap test result (a test where cells are collected from your cervix to check for early signs of cancer)?",
      render: () => (
        <Select value={responses.ID14 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID14', value)}>
          <SelectTrigger id="ID14">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 25 && responses.ID1 <= 69 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes') &&
        responses.ID11 === (language === 'fr' ? 'Non' : 'No') && responses.ID13 === (language === 'fr' ? 'Non' : 'No') && !responses.ID5.includes(language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer'),
    },
    {
      id: 'ID15',
      label: language === 'fr' ? 'Quand avez-vous fait votre dernier test Pap ?' : 'When was your last Pap test?',
      render: () => (
        <Select value={responses.ID15 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID15', value)}>
          <SelectTrigger id="ID15">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Il y a plus de 3 ans' : 'Over 3 years ago'}>{language === 'fr' ? 'Il y a plus de 3 ans' : 'Over 3 years ago'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Au cours des 3 dernières années' : 'Within the last 3 years'}>{language === 'fr' ? 'Au cours des 3 dernières années' : 'Within the last 3 years'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Jamais' : 'Never'}>{language === 'fr' ? 'Jamais' : 'Never'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 25 && responses.ID1 <= 69 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes') &&
        responses.ID11 === (language === 'fr' ? 'Non' : 'No') && responses.ID13 === (language === 'fr' ? 'Non' : 'No') && responses.ID14 === (language === 'fr' ? 'Non' : 'No') && !responses.ID5.includes(language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer'),
    },
    {
      id: 'ID15a',
      label: language === 'fr' ? 'Avez-vous eu au moins 3 tests Pap normaux au cours des 10 dernières années ?' : 'Have you had 3 or more normal Pap tests in the last 10 years?',
      render: () => (
        <Select value={responses.ID15a || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID15a', value)}>
          <SelectTrigger id="ID15a">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 70 && responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID10 === (language === 'fr' ? 'Oui' : 'Yes') &&
        !responses.ID5.includes(language === 'fr' ? 'Cancer du col de l\'utérus' : 'Cervical cancer'),
    },
    {
      id: 'ID16',
      label: language === 'fr' ? 'Avez-vous déjà fait une prise de sang pour vérifier votre cholestérol ?' : 'Have you ever had a blood test to check your cholesterol levels?',
      render: () => (
        <Select value={responses.ID16 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID16', value)}>
          <SelectTrigger id="ID16">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: ((responses.ID2 === (language === 'fr' ? 'Homme' : 'Male') && responses.ID1 >= 40) || (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 50)) && !responses.ID5.includes(language === 'fr' ? 'Cholestérol élevé' : 'High cholesterol'),
    },
    {
      id: 'ID17',
      label: language === 'fr' ? 'Est-ce que cela fait plus de 5 ans ?' : 'Was it more than 5 years ago?',
      render: () => (
        <Select value={responses.ID17 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID17', value)}>
          <SelectTrigger id="ID17">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: ((responses.ID2 === (language === 'fr' ? 'Homme' : 'Male') && responses.ID1 >= 40) || (responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 50)) &&
      responses.ID16 === (language === 'fr' ? 'Oui' : 'Yes') && !responses.ID5.includes(language === 'fr' ? 'Cholestérol élevé' : 'High cholesterol'),
    },
    {
      id: 'ID18',
      label: language === 'fr' ? 'Est-ce que vous fumez présentement ?' : 'Do you currently smoke?',
      render: () => (
        <Select value={responses.ID18 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID18', value)}>
          <SelectTrigger id="ID18">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 55 && !responses.ID5.includes(language === 'fr' ? 'Cancer du poumon' : 'Lung cancer'),
    },
    {
      id: 'ID19',
      label: language === 'fr' ? 'Avez-vous arrêté de fumer il y a plus de 15 ans ?' : 'Did you quit smoking more than 15 years ago?',
      render: () => (
        <Select value={responses.ID19 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID19', value)}>
          <SelectTrigger id="ID19">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Jamais fumé' : 'Never smoked'}>{language === 'fr' ? 'Jamais fumé' : 'Never smoked'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 55 && responses.ID1 <= 74 && responses.ID18 === (language === 'fr' ? 'Non' : 'No') && !responses.ID5.includes(language === 'fr' ? 'Cancer du poumon' : 'Lung cancer'),
    },
    {
      id: 'ID20',
      label: language === 'fr' ? 'Pendant combien d\'années avez-vous fumé ?' : 'For how many years did you smoke/have you smoked?',
      render: () => (
        <Input
          id="ID20"
          type="number"
          value={responses.ID20 || ''}
          onChange={(e) => handleInputChange('ID20', parseInt(e.target.value))}
          placeholder={language === 'fr' ? 'Entrez le nombre d\'années' : 'Enter number of years'}
        />
      ),
      condition: responses.ID1 >= 55 && responses.ID1 <= 74 && (responses.ID18 === (language === 'fr' ? 'Oui' : 'Yes') || (responses.ID18 === (language === 'fr' ? 'Non' : 'No') && responses.ID19 === (language === 'fr' ? 'Non' : 'No'))),
    },
    {
      id: 'ID21',
      label: language === 'fr' ? 'En moyenne, combien de paquets de cigarettes fumez-vous/fumiez-vous par jour ?' : 'On average, how many packs of cigarettes do/did you smoke per day?',
      render: () => (
        <Select value={responses.ID21 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => {
          if (value !== (language === 'fr' ? 'Sélectionner' : 'Select')) {
            const numericValue = value === '2' ? 2 : parseFloat(value);
            handleInputChange('ID21', numericValue);
          }
        }}>
          <SelectTrigger id="ID21">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value="0.25">1/4</SelectItem>
            <SelectItem value="0.5">1/2</SelectItem>
            <SelectItem value="1">1</SelectItem>
            <SelectItem value="2">{language === 'fr' ? '2 ou plus' : '2 or more'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 55 && responses.ID1 <= 74 && (responses.ID18 === (language === 'fr' ? 'Oui' : 'Yes') || (responses.ID18 === (language === 'fr' ? 'Non' : 'No') && responses.ID19 === (language === 'fr' ? 'Non' : 'No'))),
    },
    {
      id: 'ID21a',
      label: language === 'fr' ? 'Avez-vous fait 3 tomodensitométries (scanners) ou plus de votre poitrine pour vérifier s\'il y avait un cancer du poumon ?' : 'Have you had 3 or more CT scans of your chest to rule out lung cancer?',
      render: () => (
        <Select value={responses.ID21a || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID21a', value)}>
          <SelectTrigger id="ID21a">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 55 && responses.ID1 <= 74 && 
                 (responses.ID18 === (language === 'fr' ? 'Oui' : 'Yes') || (responses.ID18 === (language === 'fr' ? 'Non' : 'No') && responses.ID19 === (language === 'fr' ? 'Non' : 'No'))) &&
                 responses.ID20 * responses.ID21 >= 30,
    },
    {
      id: 'ID22',
      label: language === 'fr' ? 'Buvez-vous en moyenne 3 verres d\'alcool ou plus par jour ?' : 'Do you have 3 or more drinks of alcohol on average per day?',
      render: () => (
        <Select value={responses.ID22 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID22', value)}>
          <SelectTrigger id="ID22">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID24',
      label: language === 'fr' ? 'Avez-vous reçu un vaccin contre le tétanos après vos 50 ans ?' : 'Did you receive a tetanus and diphteria vaccine (Td) after you turned 50?',
      render: () => (
        <Select value={responses.ID24 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID24', value)}>
          <SelectTrigger id="ID24">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50,
    },
    {
      id: 'ID25',
      label: language === 'fr' ? 'Avez-vous reçu la série de vaccins contre le zona ?' : 'Have you received the shingles vaccine series?',
      render: () => (
        <Select value={responses.ID25 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID25', value)}>
          <SelectTrigger id="ID25">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 50 && (responses.ID13 === (language === 'fr' ? 'Oui' : 'Yes') || responses.ID1 >= 50),
    },
    {
      id: 'ID26',
      label: language === 'fr' ? 'Avez-vous reçu un vaccin contre le pneumocoque à l\'âge adulte ?' : 'Have you received a pneumococcal vaccine as an adult?',
      render: () => (
        <Select value={responses.ID26 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID26', value)}>
          <SelectTrigger id="ID26">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 65,
    },
    {
      id: 'ID27',
      label: language === 'fr' ? 'Vous êtes-vous déjà cassé un os après une petite chute ou une blessure mineure ?' : 'Have you ever broken a bone from a minor fall or injury?',
      render: () => (
        <Select value={responses.ID27 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID27', value)}>
          <SelectTrigger id="ID27">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 65 && !responses.ID5.includes(language === 'fr' ? 'Fracture de fragilité (ostéoporose)' : 'Fragility fracture (osteoporosis)'),
    },
    {
      id: 'ID28',
      label: language === 'fr' ? 'L\'un de vos parents s\'est-il déjà cassé la hanche ?' : 'Has either of your parents broken their hip?',
      render: () => (
        <Select value={responses.ID28 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID28', value)}>
          <SelectTrigger id="ID28">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 65 && !responses.ID5.includes(language === 'fr' ? 'Fracture de fragilité (ostéoporose)' : 'Fragility fracture (osteoporosis)'),
    },
    {
      id: 'ID29',
      label: language === 'fr' ? 'Prenez-vous actuellement des médicaments à base de cortisone (corticoïdes) par la bouche ?' : 'Are you currently taking an oral steroid medication (glucocorticoid)?',
      render: () => (
        <Select value={responses.ID29 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID29', value)}>
          <SelectTrigger id="ID29">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID2 === (language === 'fr' ? 'Femme' : 'Female') && responses.ID1 >= 65 && !responses.ID5.includes(language === 'fr' ? 'Fracture de fragilité (ostéoporose)' : 'Fragility fracture (osteoporosis)'),
    },
    {
      id: 'transitionSlide3',
      label: language === 'fr' ? "Presque fini! Passons aux questions sur le risque de diabète." : "Almost done! Next, a few questions about diabetes risk.\nThis helps us make sure you're getting the care you need.",
      render: () => (
        <div className="transition-slide">
          <p>{questions.find(q => q.id === 'transitionSlide3').label.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}</p>
        </div>
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID30',
      label: language === 'fr' ? 'Quelle est votre taille approximative (en cm) ?' : 'What is your approximate height (in cm)?',
      render: () => (
        <Input
          id="ID30"
          type="number"
          value={responses.ID30 || ''}
          onChange={(e) => handleInputChange('ID30', parseInt(e.target.value))}
          placeholder={language === 'fr' ? 'Entrez votre taille' : 'Enter your height'}
        />
      ),
      condition: responses.ID1 >= 18,
    },
    {
      id: 'ID31',
      label: language === 'fr' ? 'Quel est votre poids approximatif (en kg) ?' : 'What is your approximate weight (in kg)?',
      render: () => (
        <Input
          id="ID31"
          type="number"
          value={responses.ID31 || ''}
          onChange={(e) => handleInputChange('ID31', parseInt(e.target.value))}
          placeholder={language === 'fr' ? 'Entrez votre poids' : 'Enter your weight'}
        />
      ),
      condition: responses.ID1 >= 18,
    },
    /*
    {
      id: 'ID32',
      label: language === 'fr' 
        ? 'À l\'aide d\'un ruban à mesurer, placez-le autour de votre taille au niveau de votre nombril. Quelle est votre circonférence de taille approximative (en cm) ?' 
        : 'Using a tape measure, place it around your waist at the level of your belly button. What is your approximate waist circumference (in cm)?',
      render: () => (
        <Input
          id="ID32"
          type="number"
          value={responses.ID32 || ''}
          onChange={(e) => handleInputChange('ID32', parseInt(e.target.value))}
          placeholder={language === 'fr' ? 'Entrez votre tour de taille' : 'Enter your waist circumference'}
        />
      ),
      condition: responses.ID1 >= 18 && !responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes'),
    },
    */
    {
      id: 'ID33',
      label: language === 'fr' ? 'Faites-vous au moins 30 minutes par jour d\'activité physique (comme la marche rapide) ?' : 'Do you do at least 30 minutes per day of physical activity (such as brisk walking)?',
      render: () => (
        <Select value={responses.ID33 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID33', value)}>
          <SelectTrigger id="ID33">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18 && !responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes'),
    },
    {
      id: 'ID34',
      label: language === 'fr' ? 'À quelle fréquence mangez-vous des fruits ou des légumes ?' : 'How often do you eat fruits or vegetables?',
      render: () => (
        <Select value={responses.ID34 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID34', value)}>
          <SelectTrigger id="ID34">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Tous les jours' : 'Daily'}>{language === 'fr' ? 'Tous les jours' : 'Daily'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Pas tous les jours' : 'Not every day'}>{language === 'fr' ? 'Pas tous les jours' : 'Not every day'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18 && !responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes'),
    },
    {
      id: 'ID35',
      label: language === 'fr' ? 'Avez-vous déjà pris des médicaments contre l\'hypertension artérielle ?' : 'Have you ever taken medication for high blood pressure?',
      render: () => (
        <Select value={responses.ID35 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID35', value)}>
          <SelectTrigger id="ID35">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18 && !responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes'),
    },
    {
      id: 'ID36',
      label: language === 'fr' ? 'Un médecin ou une infirmière vous a-t-il déjà dit que votre taux de sucre dans le sang était élevé ?' : 'Have you ever been told by a doctor or nurse that you had high blood sugar?',
      render: () => (
        <Select value={responses.ID36 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID36', value)}>
          <SelectTrigger id="ID36">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18 && !responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes'),
    },
    {
      id: 'ID37',
      label: language === 'fr' ? 'Quand avez-vous fait votre dernier test HbA1c (un test pour mesurer votre glycémie moyenne au cours des derniers mois) ?' : 'When was your last HbA1c test (a test to measure your average blood sugar over the previous few months)?',
      render: () => (
        <Select value={responses.ID37 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID37', value)}>
          <SelectTrigger id="ID37">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            {findRiscScore >= 15 && findRiscScore <= 20 && (
              <>
                <SelectItem value="never">{language === 'fr' ? 'Je n\'ai jamais passé de test A1C' : 'I\'ve never had an A1C test'}</SelectItem>
                <SelectItem value="last3years">{language === 'fr' ? 'Au cours des 3 dernières années' : 'In the last 3 years'}</SelectItem>
                <SelectItem value="moreThan3years">{language === 'fr' ? 'Il y a plus de 3 ans' : 'More than 3 years ago'}</SelectItem>
              </>
            )}
            {findRiscScore >= 21 && (
              <>
                <SelectItem value="never">{language === 'fr' ? 'Je n\'ai jamais passé de test A1C' : 'I\'ve never had an A1C test'}</SelectItem>
                <SelectItem value="last1year">{language === 'fr' ? 'Au cours de la dernière année' : 'In the last 1 year'}</SelectItem>
                <SelectItem value="moreThan1year">{language === 'fr' ? 'Il y a plus d\'un an' : 'More than 1 year ago'}</SelectItem>
              </>
            )}
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 18 && !responses.ID5.includes(language === 'fr' ? 'Diabète' : 'Diabetes') && findRiscScore >= 15,
    },
    {
      id: 'ID38',
      label: language === 'fr' ? 'Avez-vous déjà eu une échographie du ventre pour vérifier votre aorte (la plus grosse artère du corps) ?' : 'Have you ever had an ultrasound of your abdomen (belly) to check your aorta (the largest artery in your body)?',
      render: () => (
        <Select value={responses.ID38 || (language === 'fr' ? 'Sélectionner' : 'Select')} onValueChange={(value) => value !== (language === 'fr' ? 'Sélectionner' : 'Select') && handleInputChange('ID38', value)}>
          <SelectTrigger id="ID38">
            <SelectValue placeholder={language === 'fr' ? 'Sélectionner' : 'Select'} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={language === 'fr' ? 'Sélectionner' : 'Select'} disabled>{language === 'fr' ? 'Sélectionner' : 'Select'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Oui' : 'Yes'}>{language === 'fr' ? 'Oui' : 'Yes'}</SelectItem>
            <SelectItem value={language === 'fr' ? 'Non' : 'No'}>{language === 'fr' ? 'Non' : 'No'}</SelectItem>
          </SelectContent>
        </Select>
      ),
      condition: responses.ID1 >= 65 && responses.ID1 <= 80 && responses.ID2 === (language === 'fr' ? 'Homme' : 'Male'),
    },
  ];

  const validQuestions = questions.filter((q, index) => index <= currentQuestionIndex || q.condition);
  const currentQuestion = validQuestions[currentQuestionIndex];

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      let nextIndex = currentQuestionIndex + 1;
      while (nextIndex < questions.length && !questions[nextIndex].condition) {
        nextIndex++;
      }
      setCurrentQuestionIndex(nextIndex);
      if (nextIndex >= questions.length || !questions[nextIndex].condition) {
        setShowPDF(true);
      }
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      let prevIndex = currentQuestionIndex - 1;
      while (prevIndex >= 0 && !questions[prevIndex].condition) {
        prevIndex--;
      }
      if (prevIndex >= 0) {
        setCurrentQuestionIndex(prevIndex);
        setShowPDF(false);
      }
    }
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(0);
    setShowIntro(true);
    setShowPDF(false);
    setResponses({
      ID1: null,
      ID2: null,
      ID3: [],
      ID4: null,
      ID5: [],
      ID6: null,
      ID6a: null,
      ID7: null,
      ID8: null,
      ID9: null,
      ID10: null,
      ID10a: null,
      ID11: null,
      ID12: null,
      ID13: null,
      ID14: null,
      ID15: null,
      ID15a: null,
      ID16: null,
      ID17: null,
      ID18: null,
      ID19: null,
      ID20: null,
      ID21: null,
      ID21a: null,
      ID22: null,
      ID24: null,
      ID25: null,
      ID26: null,
      ID27: null,
      ID28: null,
      ID29: null,
      ID30: null,
      ID31: null,
      // ID32: null,
      ID33: null,
      ID34: null,
      ID35: null,
      ID36: null,
      ID37: null,
      ID38: null,
    });
  };

  const renderRadioOptions = (options) => {
    return (
      <div className="radio-group">
        {options.map((option) => (
          <div key={option.value} className="radio-option">
            <input
              type="radio"
              id={`${currentQuestion.id}-${option.value}`}
              name={currentQuestion.id}
              value={option.value}
              checked={responses[currentQuestion.id] === option.value}
              onChange={() => handleInputChange(currentQuestion.id, option.value)}
            />
            <label htmlFor={`${currentQuestion.id}-${option.value}`}>
              <span className="radio-button"></span>
              {option.label}
            </label>
              </div>
        ))}
      </div>
    );
  };

  const renderIntro = () => (
    <div className="intro-container">
      <header className="intro-header">
        <h1>PCare+</h1>
        <p>{language === 'fr' ? 'Votre bilan de santé personnalisé' : 'Your Personalized Health Screening Tool'}</p>
      </header>
      
      <main className="intro-main">
        <section className="intro-welcome">
          <h2>{language === 'fr' ? 'Bienvenue 👋' : 'Welcome 👋'}</h2>
          <p>{language === 'fr' ? 'Obtenez en quelques minutes des recommandations de santé personnalisées.' : 'Get personalized health screening recommendations in just a few minutes.'}</p>
        </section>
        
        <section className="intro-features">
          <h3>{language === 'fr' ? 'Ce que PCare+ vous offre:' : 'What PCare+ Offers:'}</h3>
          <ul>
            <li>{language === 'fr' ? 'Des conseils de santé adaptés à votre situation' : 'Tailored health advice based on your responses'}</li>
            <li>{language === 'fr' ? 'Des recommandations fondées sur des preuves scientifiques' : 'Evidence-based screening recommendations'}</li>
            <li>{language === 'fr' ? 'Un rapport PDF téléchargeable pour vous et votre professionnel de santé' : 'Downloadable PDF report for you and your healthcare provider'}</li>
          </ul>
        </section>
        
        <section className="intro-privacy">
          <h3>{language === 'fr' ? '🔒 Vos données sont protégées' : '🔒 Your Privacy Matters'}</h3>
          <p>{language === 'fr' ? 'Vos informations restent confidentielles et sécurisées.' : 'All information you provide is confidential and secure.'}</p>
        </section>
        
        <section className="intro-supported-by">
          <h3 className="intro-section-header">{language === 'fr' ? '🤝 Soutenu par :' : '🤝 Supported by:'}</h3>
          <div className="logo-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '50px' }}>
            <img src={cwcLogo} alt="CWC Logo" className="support-logo" style={{ width: '100px', height: 'auto', marginRight: '50px' }} />
            <img src={ctfLogo} alt="CTF Logo" className="support-logo" style={{ width: '300px', height: 'auto', marginLeft: '50px' }} />
          </div>
        </section>
      </main>

      <footer className="intro-footer">
        <button className="start-button" onClick={() => setShowIntro(false)}>
          {language === 'fr' ? 'Commencer le questionnaire' : 'Start Questionnaire'}
        </button>
        <p className="intro-disclaimer">
          {language === 'fr' ? 'Cet outil est à titre informatif uniquement et ne remplace pas l\'avis médical professionnel.' : 'This tool is for informational purposes only and does not replace professional medical advice.'}
        </p>
      </footer>
    </div>
  );

  const progress = ((currentQuestionIndex + 1) / validQuestions.length) * 100;

  return (
    <Card className="w-full max-w-3xl mx-auto h-screen flex flex-col">
      <CardContent className="flex-grow flex flex-col justify-between">
        <div className="p-4 flex justify-end">
          <button onClick={toggleLanguage} className="px-4 py-2 bg-blue-500 text-white rounded">
            {language === 'en' ? 'Français' : 'English'}
          </button>
        </div>
        {showIntro ? (
          renderIntro()
        ) : (
          <>
            {showPDF ? (
              <div className="flex flex-col items-center justify-center h-full">
                <h2 className="text-2xl font-bold mb-4">{language === 'fr' ? 'Terminé ! 🎉' : 'All done! 🎉'}</h2>
                <p className="text-lg mb-6">{language === 'fr' ? 'Ouvrez le PDF pour voir votre plan de santé personnalisé.' : 'Download the PDF to see your personalized health plan.'}</p>
                <PDFDownloadLink
                  document={<PDFDocument responses={responses} questions={questions} findRiscScore={findRiscScore} language={language} />}
                  fileName="health_questionnaire_responses.pdf"
                  className="nav-button mb-4"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (language === 'fr' ? 'Génération de PDF...' : 'Generating PDF...') : (language === 'fr' ? 'Télécharger le PDF' : 'Download PDF')
                  }
                </PDFDownloadLink>
              </div>
            ) : (
              currentQuestion && (
                <div className="space-y-6 flex-grow">
                  {currentQuestion.id === 'transitionSlide' || currentQuestion.id === 'transitionSlide2' || currentQuestion.id === 'transitionSlide3' ? (
                    <div className="transition-slide">
                      <h2 className="text-2xl font-bold mb-4">{currentQuestion.label.split('\n')[0]}</h2>
                      <p className="text-lg">{currentQuestion.label.split('\n')[1]}</p>
                    </div>
                  ) : (
                    <>
                      <h2 className="question-label">{currentQuestion.label}</h2>
                      {currentQuestion.type === 'select' ? 
                        renderRadioOptions(currentQuestion.options) : 
                        currentQuestion.render()
                      }
                    </>
                  )}
                </div>
              )
            )}
            <div className="mt-auto">
              <div className="navigation">
                <button 
                  className="nav-button" 
                  onClick={handleBack} 
                  disabled={currentQuestionIndex === 0 || !questions.slice(0, currentQuestionIndex).some(q => q.condition)}
                >
                  {language === 'fr' ? 'Précédent' : 'Previous'}
                </button>
                <button className="nav-button" onClick={handleRestart}>
                  {language === 'fr' ? 'Redémarrer' : 'Restart'}
                </button>
                {!showPDF && (
                  <button 
                    className="nav-button" 
                    onClick={handleNext}
                    disabled={
                      (currentQuestion.id === 'ID1' && (responses.ID1 === null || responses.ID1 < 18)) ||
                      (currentQuestion.id === 'ID2' && (responses.ID2 === null || responses.ID2 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID4' && (responses.ID4 === null || responses.ID4 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID6' && (responses.ID6 === null || responses.ID6 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID6a' && (responses.ID6a === null || responses.ID6a === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID7' && (responses.ID7 === null || responses.ID7 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID8' && (responses.ID8 === null || responses.ID8 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID9' && (responses.ID9 === null || responses.ID9 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID10' && (responses.ID10 === null || responses.ID10 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID10a' && (responses.ID10a === null || responses.ID10a === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID11' && (responses.ID11 === null || responses.ID11 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID12' && (responses.ID12 === null || responses.ID12 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID13' && (responses.ID13 === null || responses.ID13 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID14' && (responses.ID14 === null || responses.ID14 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID15' && (responses.ID15 === null || responses.ID15 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID15a' && (responses.ID15a === null || responses.ID15a === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID16' && (responses.ID16 === null || responses.ID16 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID17' && (responses.ID17 === null || responses.ID17 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID18' && (responses.ID18 === null || responses.ID18 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID19' && (responses.ID19 === null || responses.ID19 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID21a' && (responses.ID21a === null || responses.ID21a === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID22' && (responses.ID22 === null || responses.ID22 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID24' && (responses.ID24 === null || responses.ID24 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID25' && (responses.ID25 === null || responses.ID25 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID26' && (responses.ID26 === null || responses.ID26 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID27' && (responses.ID27 === null || responses.ID27 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID28' && (responses.ID28 === null || responses.ID28 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID29' && (responses.ID29 === null || responses.ID29 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID30' && (responses.ID30 === null || responses.ID30 === '')) ||
                      (currentQuestion.id === 'ID31' && (responses.ID31 === null || responses.ID31 === '')) ||
                      (currentQuestion.id === 'ID33' && (responses.ID33 === null || responses.ID33 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID34' && (responses.ID34 === null || responses.ID34 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID35' && (responses.ID35 === null || responses.ID35 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID36' && (responses.ID36 === null || responses.ID36 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID37' && (responses.ID37 === null || responses.ID37 === (language === 'fr' ? 'Sélectionner' : 'Select'))) ||
                      (currentQuestion.id === 'ID38' && (responses.ID38 === null || responses.ID38 === (language === 'fr' ? 'Sélectionner' : 'Select')))
                    }
                  >
                    {language === 'fr' ? 'Suivant' : 'Next'}
                  </button>
                )}
              </div>
              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default DynamicQuestionnaire;